.referal {
  display: grid;
  max-width: 100vw !important;
  min-height: 690px;
  height: calc(100vh - 80px);
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  position: relative;
  background-color: #d1efe9;
  padding: 68px 15px 5px 35px;
  overflow: hidden;
  &-container {
    min-height: 685px;
    height: calc(100vh - 80px);
    display: grid;
    grid-template-rows: repeat(13, 1fr);
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
    padding: 0 70px;
    column-gap: 50px;
  }
  &-image {
    grid-row: 1/14;
    grid-column: 1fr;
    background-color: #015b4b;
    background: url("../../../../public/images/refer.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &-main {
    grid-row: 1/14;
    grid-column: 1fr;
    display: grid;
    justify-content: center;
    align-content: center;
    grid-template-columns: 80;
    text-align: center;
    border: 2px solid #015b4ba4;
    align-self: center;
    border-radius: 10px;
    padding: 50px;
    justify-self: center;
  }
  &-head {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.3;
    color: #015b4b;
    padding: 10px;
    max-width: 250px;
    justify-self: center;
  }
  &-desc {
    font-weight: 600;
    margin-top: 5px;
    color: #00000071;
    max-width: 350px;
    justify-self: center;
    width: 100%;
  }
  &-link {
    display: grid;
    column-gap: 20px;
    background-color: #00ffd035;
    padding: 16px 25px;
    margin-top: 20px;
    border-radius: 28px;
    grid-template-columns: auto 20px;
    max-width: 280px;
    width: calc(100% - 20px);
    justify-self: center;
    color: #004236;

    :first-child {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    :last-child {
      font-size: 18px;
      cursor: pointer;
      cursor: pointer;
    }
  }
  &-body {
    margin-top: 20px;
    display: grid;
    padding: 0 5%;
    width: 100%;
    max-width: 350px;
    justify-self: center;
    :first-child {
      border: none;
    }
    &-params {
      display: grid;
      height: 30px;
      grid-template-columns: repeat(2, auto);
      font-weight: 600;
      color: #015b4b;
      border-top: 2px solid #00000050;
      padding: 0 8px;
      padding-top: 20px;

      :first-child {
        justify-self: start;
      }

      :last-child {
        justify-self: end;
      }
    }
    &-btn {
      margin-top: 25px;
      height: 55px;
      background-color: #015b4b;
      border-radius: 5px;
      display: grid;
      align-content: center;
      font-size: 18px;
      color: #fff;
      font-weight: 600;
      letter-spacing: 1px;
      margin-bottom: 35px;
      max-width: 350px;
      width: 100%;
      justify-self: center;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 600px) {
  .referal {
    padding: 70px 15px 5px 15px;
  }
}

@media screen and (max-width: 768px) {
  .referal {
    &-container {
      padding: 0;
      column-gap: 0;
    }
    &-image {
      grid-row: 1/5;
      grid-column: span 12;
      background-position-x: center;
      background-position-y: 0px;
      background-size: cover;
    }
    &-main {
      grid-row: 5/14;
      grid-column: span 12;
      border: none;
    }
    &-body {
      width: 100%;
    }
  }
}

@media screen and (min-width: 1440px) {
  .referal {
    &-container {
      column-gap: 100px;
    }
  }
}

@media screen and (min-width: 1540px) {
  .referal {
    &-container {
      column-gap: 10px;
    }
    &-image {
      background-size: 800px;
    }
  }
}
