.profits {
  max-width: 100vw !important;
  min-height: 500px;
  height: 100vh;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  position: relative;
  background-color: #d1efe9;
  overflow: hidden;

  &-container {
    padding: 80px 30px 20px 30px;
    display: grid;
    grid-template-rows: 50px 1fr;
    row-gap: 20px;
  }
  &-body {
    max-height: calc(100vh - 165px);
    overflow: hidden;
    padding: 15px;
    background-color: #d1efe9;
    display: grid;
  }
  &-head {
    font-size: 24px;
    font-weight: 600;
    align-self: center;
    padding: 0 15px;
    color: #015b4b;
  }
  &-table {
    box-shadow: 0 0 15px #00000020;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    display: grid;
    overflow-y: scroll;

    &-container {
      display: grid;
      border: 1px solid #00000036;
      border-radius: 5px;
      padding: 10px;
      display: grid;
      align-content: start;

      .custom-table-head {
        color: #015b4b !important;
        font-size: 18px;
      }
      .custom-table-body {
        :nth-child(3) {
          color: #000;
        }
        :nth-child(5) + {
          color: #00000087;
        }
        height: 60px;
      }
    }
  }
  #table_rad {
    border-radius: 10px !important;
  }
}

#active_nav {
  background-color: #fff;
  border: 1px solid #fff;
}

@media screen and (max-width: 600px) {
  .profits-container {
    .custom-table-head {
      font-size: 17px !important;
    }
    .custom-table-body {
      font-size: 15px !important;
    }
  }
  .profits-container {
    padding: 80px 0px 20px 0px;
  }
  .profits-navigation {
    grid-template-columns: repeat(3, 100px);
    column-gap: 0;
    div {
      font-size: 13px;
    }
  }
  .profits {
    &-container {
      background-color: #fff;
    }
    &-table {
      width: calc(100vw - 40px);
      border-radius: 0;
    }
    &-body {
      background-color: #fff;
      padding: 15px 0;
      height: 100vh;
      min-height: 500px;
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  .profits-container {
    padding: 80px 0px 0px 20px;
  }
}
