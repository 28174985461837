.transactions {
  max-width: 100vw !important;
  min-height: 500px;
  height: 100vh;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  position: relative;
  background-color: #d1efe9;
  overflow: hidden;

  &-container {
    padding: 70px 30px 20px 30px;
    display: grid;
    grid-template-rows: 50px 1fr;
    row-gap: 0;
  }
  &-body {
    max-height: calc(100vh - 165px);
    border-radius: 10px;
    overflow: hidden;
    padding: 15px;
    background-color: #d1efe9;
    display: grid;
    grid-template-rows: 80px 1fr;
  }
  &-head {
    font-size: 24px;
    font-weight: 600;
    align-self: last baseline;
    padding: 0 15px;
    color: #015b4b;
  }
  &-navigation {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: 1fr;
    justify-content: start;
    align-items: last baseline;
    column-gap: 10px;
    color: #015b4b;
    div {
      display: grid;
      align-items: center;
      background-color: transparent;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      height: 50px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      cursor: pointer;
      width: 120px;
    }
    :nth-child(2) {
      width: 140px;
    }
  }
  &-table {
    background-color: #fff;
    border-top-left-radius: 0px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 20px;
    display: grid;
    overflow-y: scroll;

    &-container {
      display: grid;
      border: 1px solid #00000036;
      border-radius: 5px;
      padding: 10px;
      display: grid;
      align-content: start;

      .custom-table-head {
        color: #015b4b !important;
        font-size: 18px;
      }
      .custom-table-body {
        height: 60px;
      }
    }
  }
  #table_rad {
    border-radius: 10px !important;
  }
}

#active_nav {
  background-color: #fff;
  border: 1px solid #fff;
}

@media screen and (max-width: 600px) {
  .transactions-container {
    .custom-table-head {
      font-size: 17px !important;
    }
    .custom-table-body {
      font-size: 15px !important;
    }
  }
  .transactions-container {
    padding: 80px 0px 20px 0px;
  }
  .transactions-navigation {
    grid-template-columns: repeat(3, auto);
    column-gap: 0px;
    div {
      width: 95px;
    }
    :nth-child(2) {
      width: 110px;
    }
    div {
      font-size: 13px;
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  .transactions-container {
    padding: 80px 0px 0px 20px;
  }
}
