.invest {
  &-page {
    max-width: 100vw !important;
    height: 100vh;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
    position: relative;
  }

  &-container {
    padding: 100px 20px 50px 40px;
    display: grid;
    grid-template-rows: 50px auto;
    max-width: 100vw;
    padding-bottom: 10px;
    background-color: #d1efe9;
  }
  &-head {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    color: #015b4b;
  }
  &-card {
    &-container {
      padding: 45px 0 20px;
      display: grid;
      height: auto;
      grid-template-columns: repeat(auto-fit, minmax(200px, 400px));
      column-gap: 25px;
      justify-content: center;
      row-gap: 40px;
    }
    overflow-x: auto;
    overflow-y: hidden;
    display: grid;
    align-content: start;
    row-gap: 5px;
    height: 490px;
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    transition: 0.5s ease;

    &:nth-child(2) {
      overflow-x: auto;
      background-color: #015b4b;
      transform: scale(1.02);
      color: #fff;
    }
    &:hover {
      box-shadow: 0 5px 8px #00000029;
    }

    &-first {
      font-size: 26px;
      color: #015b4b;
    }
    &-second,
    &-third {
      font-size: 26px;
      display: grid;
      grid-template-columns: repeat(2, auto);
      column-gap: 20px;
      font-weight: 600;
      margin-top: 10px;

      :last-child {
        justify-self: end;
      }
    }
    &-third {
      font-weight: normal;
      margin-top: 0px;
      font-size: 15px;

      :first-child {
        color: #015b4b;
      }
    }
    &-desc {
      display: grid;
      row-gap: 10px;
      margin-top: 50px;

      &-item {
        display: grid;
        grid-template-columns: 25px auto;
        grid-template-rows: 30px;
        text-align: left;
        justify-content: start;
        align-content: center;
        align-items: center;
        column-gap: 10px;
        :first-child {
          display: grid;
          align-items: center;
          border-radius: 100%;
          text-align: center;
          height: 16px;
          padding: 5px 0;
        }
      }
    }
    &-amount {
      margin-top: 15px;
      display: grid;
      row-gap: 10px;
      color: #015b4b;
      input {
        height: 15px;
        padding-left: 8px;
        border-color: #015b4b;
      }
    }
    &-btn {
      display: grid;
      align-content: center;
      background-color: #015b4b;
      height: 40px;
      border-radius: 5px;
      margin-top: 10px;
      color: #fff;
      text-align: center;
      font-size: 18px;
      cursor: pointer;
    }
  }
}

.Professional {
  input {
    background-color: #015b4b;
    border-color: #fff;
    color: #fff;
  }
  input::placeholder {
    color: #e3e3e3;
  }
  .fas,
  .invest-card-filter,
  .invest-card-amount {
    color: #fff;
  }
  .fa-solid {
    background-color: #fff;
  }
  .invest-card-btn {
    background-color: #fff;
    color: #015b4b !important;
  }
}

#disabled {
  background-color: #72b9ac;
  cursor: default;
  // color: #015b4b;
}

.fa-check {
  background-color: #01f3c72e;
  color: #015b4b;
  font-size: 16px;
}

.fa-xmark {
  color: #d50000 !important;
  background-color: #f6000041;
  font-size: 16px;
}

@media screen and (max-width: 600px) {
  .invest {
    &-container {
      padding: 120px 20px 20px 20px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .invest-card-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 350px));
  }
}

@media screen and (min-width: 1624px) {
  .invest-card-container {
    column-gap: 60px;
    padding-top: 100px;
  }
}

@media screen and (max-width: 420px) {
  .invest-card-container {
    // display: none;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)) !important;
  }
}
