.header {
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
  z-index: 100;
  position: fixed;
  user-select: none;
  display: grid;
  width: calc(100vw - 20px);
  grid-template-columns: 1fr 50px;
  grid-template-rows: 1fr;
  padding: 0 10px;
  align-content: center;
  align-items: center;
  height: 85px;
  background-color: transparent;
  column-gap: 30px;
  transition: 0.3s ease-in all;
}

#sticky {
  background-color: #fff;
  box-shadow: 0 1px 5px #00000029;
}

#logo {
  width: 100px;
  font-weight: 500;
  font-size: 30px;
  display: grid;
  justify-self: start;
  >img{
    width: 65%;
  }
}

.header div {
  /* background-color: #000; */
}

.menubar {
  align-self: center;
  justify-self: center;
  height: 38px;
}

.menubar span {
  user-select: none;
  font-size: 32px !important;
  cursor: pointer;
  transition: 0.2s ease-out transform;
}

.menubar span:hover {
  transform: scale(1.1);
}

.actions div:hover,
.xActions div:hover {
  transform: scale(1.05);
}

.menubar span:active {
  transform: scale(0.6);
}

.actions div:active,
.xActions div:active {
  transform: scale(0.95);
}

.actions,
.xActions {
  justify-content: end;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 50px;
  column-gap: 20px;
}

.actions {
  display: none;
}

.actions div {
  align-self: center;
  max-width: 200px;
  background-color: #000;
  display: grid;
  align-content: center;
  justify-content: center;
  font-size: 20px;
  color: #fff;
}

.xActions {
  padding-right: 20px;
  padding-bottom: 20px;
  grid-template-columns: repeat(auto-fit, minmax(120px, 160px));
  justify-content: start;
  grid-gap: 20px;

  > div {
    max-width: 160px;
  }
}

.xActions div,
.actions div {
  background-color: #015b4b;
  border-radius: 5px;
  display: grid;
  align-content: center;
  justify-content: center;
  font-size: 18px;
  color: #fff;
  height: 40px;
  user-select: none;
  cursor: pointer;
  transition: 0.2s ease-out all;
}

.xActions div {
  height: 50px;
}

.xMenu {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 500;
  position: fixed;
  background-color: #fff;
  width: calc(100vw - 10px);
  max-width: 360px;
  height: 100vh;
  left: 0;
  top: 0px;
  padding: 90px 35px;
  padding-left: 5vw;
  display: grid;
  align-content: start;
  row-gap: 50px;
  overflow-y: auto;
  transform: translateX(-120vw);
  transition: 0.3s ease-out transform;
  z-index: 2;
  user-select: none;
}

#slide {
  transform: translate(0);
}

.lists {
  display: grid;
  row-gap: 50px;
  align-content: start;
  font-size: 20px;
  justify-items: start;
}

li {
  cursor: pointer;
}

.menu {
  display: none;
  grid-template-columns: repeat(4, auto);
  column-gap: 8%;
  font-weight: 500;
  font-size: 18px;
  justify-content: center;
}

.menu div {
  cursor: pointer;
  transition: 0.2s ease transform;
}

.menu div:hover {
  transform: scale(1.1);
}

.menu div:active {
  transform: scale(0.99);
}

.container {
  padding: 0;
  display: grid;
  row-gap: 0px;
  position: relative;
  z-index: 1;
}

.trade {
  padding: 0 5vw;
  height: 900px;
  align-items: center;
  align-content: center;
  background: #d1efe9;
  display: grid;
  grid-template-rows: repeat(2, auto);
  row-gap: 50px;
}

.trade_content {
  display: grid;
  row-gap: 30px;
  font-family: "Varela Round", sans-serif;
}

.trade_head {
  color: #000;
  font-weight: 600;
  font-size: 32px;
  line-height: 1.5;
}

.trade_title {
  font-size: 14px;
  color: #003f34;
  border-radius: 10rem;
  border: 1px solid #01564695;
  justify-self: start;
  padding: 8px 15px;
  text-align: center;
  line-height: 1.7;
}

.trade_desc {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
  color: #525252;
}

.trade_image {
  display: grid;
  justify-items: center;
  align-items: center;
  /* padding: 0 0 200px; */
}

.trade_image img {
  border-radius: 10px;
}

.marquee {
  height: 40px;
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.iframe {
  max-height: 40px;
  overflow: auto;
}

iframe {
  width: 100%;
}

.safety {
  background-color: #fff;
  /* height: 1500px; */
  padding: 50px 5vw;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 1fr;
  grid-gap: 30px;
  align-content: start;
}

.safetyHead {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}

.safetyContainer {
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  justify-items: center;
  /* grid-template-rows: repeat(auto-fit, minmax(300px, 1fr)); */
}

.safetyCards {
  justify-self: center;
  max-width: 400px;
  min-height: 250px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
  padding: 30px;
  row-gap: 20px;
  /* align-content: center; */
  justify-items: center;
}

.safetyCards .cardIcon {
  font-size: 50px;
  height: 80px;
  color: #003f34;
}

.safetyCards .cardTitle {
  height: auto;
  font-size: 24px;
  font-weight: 500;
  color: #003f34;
  text-align: center;
}

.safetyCards .cardDesc {
  text-align: center;
  color: #0000009f;
  font-family: "Varela Round", sans-serif;
  line-height: 2;
}

.explore {
  background-color: #ffdae2;
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 1fr;
  row-gap: 20px;
  padding: 80px 5vw;
  justify-content: center;
  justify-items: center;
}

.invest {
  background-color: #89ffe90e;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 1fr;
  row-gap: 20px;
  padding: 100px 5vw;
  /* justify-content: center; */
  /* justify-items: center; */
}

.investHead {
  color: #003f34;
  /* text-shadow: 0 0 2px #000; */
  /* font-size: 28px !important; */
}

.exploreHead,
.investHead {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
}

.exploreTitle,
.investTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.7;
  color: #0000008b;
  font-family: "Varela Round", sans-serif;
  text-align: center;
  width: 80%;
}

.exploreContainer,
.investContainer {
  display: grid;
  grid-template-rows: auto auto;
  row-gap: 50px;
  grid-template-columns: 1fr;
  padding-top: 50px;
  /* background-color: red; */
}

.exploreCardContainer,
.investCardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  align-content: center;
}

.exploreCards,
.investCards {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(6, 1fr);
  align-content: center;
  align-items: center;
  padding: 10px;
  grid-gap: 5px;
  background-color: #fff;
  border-radius: 10px;
  height: 80px;
  cursor: pointer;
  transition: 0.3s ease;
}

.exploreCards:hover,
.investCards:hover {
  transform: scale(1.03);
  box-shadow: 0 0 20px #00000029;
}

.exploreCards .cardIcon,
.investCards .cardIcon {
  grid-row: 1/7;
  grid-column: 1/3;
  justify-self: center;
  font-size: 30px;
  color: #ff476f;
  background-color: #ffabbd23;
  align-self: center;
  border-radius: 100%;
  width: 44px;
  text-align: center;
}

.investCards .cardIcon {
  color: #002f27;
  background-color: #3effdc27;
}

.exploreCards .cardTitle,
.investCards .cardTitle {
  grid-row: 1/3;
  grid-column: 3/13;
  padding-left: 5px;
  font-family: "Varela Round", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-top: 15px;
}

.exploreCards .cardDesc,
.investCards .cardDesc {
  grid-row: 3/7;
  grid-column: 3/13;
  font-size: 12px;
  color: #7f7f7f;
  font-weight: 500;
  padding-left: 5px;
  font-family: "Varela Round", sans-serif;
  /* align-self: start; */
}

.exploreImage,
.investImage {
  display: grid;
  align-content: center;
  justify-self: center;
  max-width: 500px;
}

.investImage {
  margin-top: -50px;
  align-self: center;
}

.accountCardContainer {
  background-color: #d1efe9;
  max-width: 100vw;
  height: 400px;
  display: grid;
  grid-template-columns: 80%;
  grid-template-rows: 70%;
  align-content: center;
  justify-content: center;
}

.accountCard {
  text-align: center;
  border-radius: 10px;
  background-color: #015b4b;
  padding: 20px;
  display: grid;
  color: #fff;
  align-content: center;
  row-gap: 25px;
}

.accountBtn {
  background-color: #fff;
  height: 45px;
  justify-self: center;
  max-width: 220px;
  border-radius: 5px;
  width: 100%;
  color: #002f27;
  font-size: 18px;
  line-height: 45px;
  font-weight: 500;
  user-select: none;
}

.accountCardTitle {
  font-size: 24px;
  font-weight: 500;
}

.accountCardDesc {
  color: #d1d1d1;
  font-family: "Varela Round", sans-serif;
  font-size: 14px;
}

.footer {
  line-height: 40px;
  font-size: 15px;
  background-color: #002f27;
  color: #fff;
  max-width: 100vw;
  text-align: center;
  font-family: "Varela Round", sans-serif;
  font-weight: 500;
}

@media screen and (min-width: 600px) {
  .actions {
    display: grid;
    transform: scale(0.9);
  }

  .header {
    padding: 0 6vw;
    width: 88vw;
    grid-template-columns: auto 300px 50px;
  }

  .xMenu {
    padding-top: 100px;
  }

  .xActions {
    display: none;
  }

  .trade_head {
    width: 70%;
  }

  .trade_desc {
    width: 85%;
  }

  .trade {
    height: 1000px;
  }

  .trade_image {
    height: 400px;
  }

  .safetyContainer {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .exploreCardContainer,
  .investCardContainer {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

@media screen and (min-width: 960px) {
  .header {
    padding: 0 8vw;
    width: 84vw;
    grid-template-columns: auto 1fr 300px;
  }

  .menubar {
    display: none;
  }

  .xMenu {
    display: none !important;
  }

  .menu {
    display: grid;
  }

  .actions {
    transform: scale(1);
  }
}

@media screen and (min-width: 820px) {
  .trade {
    padding: 0 7vw;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    height: 600px;
  }

  .safety,
  .explore,
  .invest {
    padding: 100px 7vw;
  }

  .trade_head {
    width: 80%;
  }

  .trade_desc {
    width: 90%;
  }

  .investHead {
    background-color: #fff;
    border: 5px solid #015b4b;
    padding: 5px 20px;
    border-radius: 10rem;
    justify-self: center;
    color: #002f27;
    font-size: 28px !important;
  }
}

@media screen and (min-width: 1024px) {
  .trade {
    min-height: 100vh;
  }

  .trade_content {
    align-self: center;
  }

  .trade_image {
    height: auto !important;
    align-self: center;
  }

  .exploreContainer {
    grid-template-columns: 5fr 4fr;
    grid-template-rows: 1fr;
    padding-top: 10px;
  }

  .investContainer {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 0px !important;
    padding-top: 10px;
  }

  .investImage {
    margin: 0;
  }

  .accountCardContainer {
    grid-template-columns: 70%;
  }
}

@media screen and (min-width: 1440px) {
  .trade {
    height: calc(100vh - 130px);
    max-height: 1150px !important;
    min-height: 0;
  }

  .exploreCardContainer,
  .investCardContainer {
    grid-gap: 50px;
  }

  .investCardContainer {
    grid-gap: 30px;
  }

  .explore,
  .invest {
    padding: 100px 8vw;
  }

  .investContainer {
    grid-template-columns: 600px 1fr !important;
    justify-self: center;
    column-gap: 20px;
  }
}

@media screen and (max-width: 1540px) and (min-width: 1440px) {
  .trade {
    height: 100vh !important;
    max-height: none !important;
  }

  .investContainer {
    grid-template-columns: 4fr 5fr;
  }
}

@media screen and (min-width: 1540px) {
  .investContainer {
    grid-template-columns: 5fr 5fr;
  }
}

@media screen and (max-width: 280px) {
  .exploreCardContainer,
  .investCardContainer {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .exploreCards .cardTitle,
  .invsetCards .cardTitle {
    margin-top: 0;
    font-size: 14px;
  }
}

.trade_btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  margin-top: 20px;
  background-color: #015b4b;
  font-size: 18px;
  font-weight: 500;
  color: #e8e8f0;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  transition: all 0.5s;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  outline: none;
  letter-spacing: 0.5px;
  user-select: none;
}

.trade_btn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.trade_btn span:after {
  content: "»";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
  z-index: 1;
}

.trade_btn:hover span {
  padding-right: 25px;
}

.trade_btn:hover span:after {
  opacity: 1;
  right: 0;
}

.trade_btn:after {
  content: "";
  background: #ffffff51;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: 0px !important;
  margin-top: 0%;
  opacity: 0;
  transition: all 0.9s;
  border-radius: 100%;
}

.trade_btn:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.fixedBg {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

#activeMenu {
  transition: 0.2s ease transform;
  color: #015b4b;
  transform: scale(1.1);
}
