.dashboard {
  max-width: 100vw !important;
  min-height: 850px;
  height: auto;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  position: relative;
  background-color: #d1efe9;

  &-container {
    padding: 80px 20px 50px 40px;
    display: grid;
    grid-template-rows: repeat(12, 1fr);
    grid-template-columns: repeat(13, 1fr);
    max-width: 100vw;
    height: calc(100vh - 50px);
    padding-bottom: 50px;
    background-color: #d1efe9;
  }

  &-overview {
    min-height: 300px;
    grid-row: 1/5;
    grid-column: 1/9;
    display: grid;
    background-color: transparent;
    align-content: center;
  }
  &-widget {
    grid-column: 1/9;
    grid-row: 5/12;
    margin-top: 35px;
    margin-bottom: -35px;
    min-height: 400px;
    display: grid;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 0 20px #00000010;
    overflow: hidden;
    grid-template-rows: auto 1fr;
    align-content: start;

    &-title {
      align-self: center;
      padding-left: 20px;
      font-size: 18px;
      font-weight: 600;
      color: #000000b2;
      line-height: 55px;
    }

    .tradingview-widget-container {
      width: 100% !important;
      height: 100% !important;
      overflow: hidden;
    }
  }

  &-account {
    margin-left: 30px;
    grid-column: 9/14;
    grid-row: 1/7;
    min-height: 320px;
    display: grid;
  }

  &-recent-transaction {
    margin-left: 30px;
    grid-column: 9/14;
    grid-row: 7/12;
    min-height: 300px;
    margin-top: 35px;
    margin-bottom: -35px;
  }
}

@media screen and (max-width: 600px) {
  .dashboard {
    &-container {
      padding: 70px 20px 20px 20px;
    }
  }
}

@media screen and (max-width: 650px) {
  .dashboard {
    &-container {
      height: 2050px !important;
    }
    &-overview {
      height: 550px;
    }
    &-widget {
      height: 550px;
    }
    &-recent-transaction {
      margin-left: 0;
      padding-bottom: 20px;
    }
  }
}

@media screen and (max-width: 1439px) {
  .dashboard-container {
    height: 1100px;
    padding-bottom: 20px;
    grid-template-columns: repeat(12, 1fr);
  }
  .dashboard {
    &-overview {
      grid-column: span 12;
      grid-row: 1/3;
    }
    &-widget {
      grid-column: span 12;
      grid-row: 3/6;
      margin-bottom: 0;
    }
    &-account {
      grid-column: span 6;
      grid-row: 6/9;
      margin-top: 30px;
      height: 410px;
      margin-left: 0;
    }
    &-recent-transaction {
      grid-column: 7/13;
      height: 410px;
      grid-row: 6/9;
      margin-top: 35px;
      padding-bottom: 20px;
    }
  }
}

@media screen and (max-width: 720px) {
  .dashboard-container {
    height: 1500px;
  }
  .dashboard {
    &-account {
      grid-column: span 12;
    }
    &-recent-transaction {
      grid-column: span 12;
      grid-row: 9/12;
    }
    &-recent-transaction {
      margin-left: 0;
      padding-bottom: 20px;
    }
  }
}

@media screen and (min-width: 1800px) {
  .dashboard {
    &-container {
      grid-template-columns: repeat(12, 1fr);
    }
    &-overview {
      grid-column: 1/10;
    }
    &-widget {
      grid-column: 1/10;
    }
    &-account {
      grid-column: 10/13;
    }
    &-recent-transaction {
      grid-column: 10/13;
    }
  }
}
