.dashboard-overview {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(11, 1fr);
  grid-gap: 25px;
  .intro {
    padding-top: 25px;
    grid-column: span 6;
    grid-row: 1/5;
    display: grid;
    align-content: start;
    row-gap: 10px;
    &-head {
      font-size: 25px;
      font-weight: 600;
      color: #015b4b;
    }
    &-desc {
      font-size: 15px;
      color: #4c4c4c;
    }
  }
  .profit,
  .balance {
    display: grid;
    grid-row: 5/12;
    border-radius: 20px;
    box-shadow: 0 0 20px #00000028;
    background: linear-gradient(to left, #007661, #004639, #004639);
  }
  .profit {
    grid-column: 4/7;
  }
  .balance {
    grid-column: 1/4;
  }
}

.overview-card {
  background-image: url(../../../public/bubbles.png);
  background-size: cover;
  background-position-x: left;
  padding: 10px 25px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr;
  row-gap: 10px;
  &-head {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: start;
    align-content: center;
    column-gap: 20px;
    font-size: 20px;
    font-weight: 500;
    align-items: center;
  }
  &-icon {
    padding: 5px 5px 4.5px;
    display: grid;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #015b30d7;
    border: 10px solid #c8fff5;
    overflow: hidden;
    width: 15px;

    span {
      color: #fff;
      font-size: 14px;
    }
  }
  &-title {
    color: #ffffff;
    font-size: 16px;
  }
  &-body {
    display: grid;
    align-content: center;
    align-items: center;
    grid-template-columns: 1fr auto;
    padding-right: 10px;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 2.5px;
    padding-left: 4px;
    color: #fff;
    padding-bottom: 20px;
    // background-color: red;

    &-btn {
      width: 40px;
      user-select: none;
      border-radius: 50%;
      color: #fff;
      font-size: 18px;
      text-align: center;
      display: grid;
      overflow: hidden;
      transition: 0.3s ease all;
      cursor: pointer;
      :last-child {
        font-size: 35px;
      }
      &:hover {
        transform: scale(1.2);
      }
      &:active {
        transform: scale(0.8);
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .overview-card {
    padding: 10px 15px;
  }
  .dashboard-overview {
    .intro {
      padding-top: 0px;
      align-content: center;
      grid-row: 1/4;
    }
    .profit,
    .balance {
      grid-column: span 6;
    }
    .balance {
      grid-row: 4/8;
    }
    .profit {
      grid-row: 8/12;
    }
  }
}

@media screen and (max-width: 800px) and (min-width: 650px) {
  .overview-card-body-btn span {
    visibility: hidden;
  }
}

@media screen and (min-width: 1800px) {
  .overview-card {
    &-title {
      font-size: 20px;
    }
    &-icon {
      span {
        font-size: 20px;
      }
    }
    &-body {
      font-size: 48px;
    }
  }
}
