.custom-table {
  &-head {
    padding: 0 15px;
    height: auto;
    display: grid;
    text-align: left;
    font-weight: 600;
    color: #000000a8;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: -5px;
    min-height: 60px !important;
  }
  &-body {
    min-width: 360px;
    padding: 0px 15px;
    height: auto;
    div {
      min-height: 50px;
    }
    display: grid;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    :nth-child(3) {
      color: #00000087;
    }
    :first-child {
      display: none;
    }
  }
  &-data {
    display: grid;
    border-top: 1px solid #54545431;
    align-items: center;
    white-space: nowrap;
  }
  &-empty {
    font-size: 120%;
    padding: 20px 10%;
    line-height: 25px;
    font-weight: bold;
    text-align: center;
    align-self: center;
    color: #00000087;
    display: grid;
    grid-template-rows: repeat(2, auto);
    row-gap: 20px;
  }
}
