.withdraw {
  .deposit{
    &-details {
    display: grid;
      div {
        display: grid;
      }
  }
  &-submit {
    margin-top: 0;
    height: auto;
    padding: 20px;
  }
  &-types{
    height: auto;
  }
  &-bank{
    width: calc(100% - 40px);
  }
}
  &-param {
    display: grid;
    row-gap: 10px;
    margin-top: 10px;
    color: #0e5548;
    font-weight: 600;
    input, select {
      padding: 13px 0;
      padding-left: 10px;
    }
  }
  
}
