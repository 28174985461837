.error-page {
  display: grid;
  align-content: center;
  justify-content: center;
  justify-items: center;
  color: #136859;
  background-color: #d1efe9;
  width: 100vw;
  height: 100vh;
  align-self: start;
  align-content: start;
}

#err_img {
  display: grid;
  justify-items: center;
}

.oops {
  padding-top: 50px;
  font-size: 40px;
  font-weight: 600;
}

.link {
  color: #136859;
  text-decoration: underline !important;
  font-weight: 500;
  text-align: center;
  justify-self: center;
  padding-top: 25px;
  font-size: 20px !important;
}
