body {
  margin: 0;
  padding: 0;
  max-width: 100vw;
  min-height: 500px;
  font-family: "Varela Round", sans-serif;
  background: #d1efe9;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  width: 6px;
  border-radius: 100rem;
  background-color: #000000ab;
}
