.settings {
  max-width: 100vw !important;
  height: auto;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  position: relative;
  background-color: #d1efe9;

  &-container {
    max-width: 100vw !important;
    padding: 80px 20px 0px 40px;
    display: grid;
    grid-template-rows: 50px auto;
    row-gap: 10px;
    grid-template-columns: 1fr;
    height: auto;
    padding-bottom: 20px;
    background-color: #d1efe9;
  }
  &-head {
    color: #015b4b;
    font-weight: 600;
    font-size: 28px;
  }
  &-cart {
    min-height: 600px;
    max-width: 800px;
    justify-content: center;
    height: auto;
    display: grid;
    grid-template-rows: repeat(12, auto);
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 2vw;
  }

  &-profile {
    background-color: #fff;
    border-radius: 10px;
    grid-row: span 12;
    grid-column: 1/7;
    padding: 20px;
    display: grid;
    grid-template-rows: auto auto 1fr;

    &-name {
      font-weight: 600;
      margin-top: -5px;
      font-size: 18px;
      color: #015b4b;
      display: grid;
      grid-template-columns: repeat(2, auto);
      align-content: center;
      align-items: center;

      :nth-child(2) {
        justify-self: end;
        font-size: 20px;
      }
    }

    &-picture {
      margin-top: 15px;
      background-color: #c3c3c3;
      height: 110px;
      width: 110px;
      justify-self: center;
      border-radius: 50%;
      display: grid;
      position: relative;
      justify-items: end;
      margin-bottom: 10px;

      div {
        position: absolute;
      }
      img,
      div {
        width: 110px;
        height: 110px;
        border-radius: 50%;
      }

      input {
        display: none;
      }
      //   align-items: center;

      span {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        position: absolute;
        bottom: 8px;
        background-color: #015b4b;
        color: #fff;
        display: grid;
        align-content: center;
        justify-content: center;
        font-size: 9px;
        cursor: pointer;
      }
    }
    &-form {
      margin-top: 10px;
      display: grid;
      grid-template-columns: 1fr;
      &-param {
        display: grid;
        row-gap: 10px;
      }
      .withdraw-param {
        div {
          display: grid;
        }
        row-gap: 0px;
      }
    }
    &-btn {
      align-self: end;
      display: grid;
      grid-template-columns: repeat(2, auto);
    }
    &-err {
      display: grid;
      align-self: center;
      align-items: center;
      color: #d70101;
      height: 10px;
      font-size: 16px;
      font-weight: 600;
      height: 45px;
    }
    &-submit {
      background-color: #015b4b;
      height: 45px;
      width: 140px;
      justify-self: end;
      align-self: end;
      display: grid;
      align-items: center;
      text-align: center;
      font-size: 18px;
      color: #fff;
      font-weight: 500;
      border-radius: 5px;
      user-select: none;
      cursor: pointer;
    }
  }
  &-reset {
    background-color: #fff;
    border-radius: 10px;
    grid-row: 1/11;
    grid-column: 7/13;
    padding: 20px;
    display: grid;
    grid-template-rows: auto 1fr;
    row-gap: 8px;
    align-content: center;
  }
}

@media screen and (max-width: 650px) {
  .settings {
    &-cart {
      column-gap: 0;
      row-gap: 30px;
    }
    &-profile {
      grid-row: 1/6;
      grid-column: span 12;
      height: 500px;
    }
    &-reset {
      grid-row: 6/13;
      grid-column: span 12;
      height: 300px;
    }
  }
}

@media screen and (max-width: 600px) {
  .settings {
    &-container {
      padding: 85px 20px 20px 20px;
    }
  }
}

@media screen and (min-width: 650px) {
  .settings-cart {
    max-height: 600px;
  }
}
