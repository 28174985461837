// DASHBOARD HEAD
.dashboardMenuContainer {
  width: 50px;
  position: relative;
  display: grid;
  height: auto;
  height: calc(100vh);
}

.dashboardHead {
  position: fixed;
  top: -1px;
  height: 60px;
  background-color: #015b4b;
  width: 100vw;
  z-index: 100 !important;
  border-bottom: 1px solid #c3c3c3;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: start;
  column-gap: 25px;
  align-content: center;
  font-size: 24px;
  color: #fff;
  font-weight: 600;
}

.headMenuBar {
  display: grid;
  align-self: center;
}

.headMenuBar span {
  font-size: 30px !important;
  cursor: pointer;
}

.mainMenu span {
  font-size: 22px !important;
}

.dashboardMenu {
  overflow-y: hidden;
  color: #00342a;
  position: fixed;
  width: 50px;
  height: calc(100vh - 80px);
  background-color: #fff;
  top: 0;
  left: 0;
  transition: 0.2s ease-in all;
  overflow-x: hidden;
  padding: 80px 10px 10px;
  display: grid;
  justify-content: start;
  grid-template-columns: 1fr;
  row-gap: 100px;
  grid-template-rows: 400px auto;
  box-shadow: 2px 0 5px #00000016;
  z-index: 90;
}

.systemMenu {
  height: auto;
  justify-self: start;
  width: 100%;
  align-self: last baseline;
  padding: 30px 0;
  align-content: center;
  align-items: center;
  display: grid;
  grid-template-rows: repeat(2, 55px);
  grid-template-columns: 100%;
  row-gap: 20px;

  a {
    width: 95%;
    justify-self: start;
    text-decoration: none;
    color: #00342a;
    align-items: center !important;
    display: grid !important;
    height: 100% !important;
  }
}

#spread {
  width: 250px;
  overflow-y: auto;
  .mainMenuItems:hover {
    background-color: #00000016;
  }
  .activeMenu {
    background-color: #dbf2ee;
  }
  .activeMenu:hover {
    background-color: #dbf2ee;
  }

  .mainMenuItemsIcon {
    background-color: transparent;
  }
  .systemMenu {
    border-top: 1px solid #00000026;
  }
}

.mainMenuItemsIcon.activeMenu {
  background-color: #dbf2ee !important;
}

.mainMenuItemsIcon {
  background-color: #fff;
  border-radius: 100%;
  padding: 10px;
  margin-left: -10px;
  overflow: hidden;
  text-align: center;
  width: 22px;
}
.mainMenuItemsIcon:hover {
  background-color: #d1efe9;
}

.mainMenuItems {
  white-space: nowrap;
}

.mainMenu {
  padding-top: 30px;
  display: grid;
  grid-template-rows: repeat(5, 55px);
  row-gap: 8%;

  a {
    justify-self: start;
    width: 95%;
    color: #00342a;
    text-decoration: none;
  }
}

.systemMenu {
  align-items: center;
}

.mainMenuItems {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: start;
  padding-left: 12px;
  align-items: center;
  align-content: center;
  column-gap: 20px;
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.mainMenuItemsIcon {
  display: grid;
  align-items: center;
}

.dashboardMenu::-webkit-scrollbar {
  width: 5px;
}

.dashboardMenu::-webkit-scrollbar-thumb {
  width: 6px;
  border-radius: 100rem;
  background-color: #000000ab;
}

a {
  text-decoration: none !important;
}

@media screen and (min-width: 1240px) {
  #spreadParent {
    width: 250px;
  }
}

@media screen and (max-width: 600px) {
  .dashboardMenuContainer {
    width: 0;
  }
  .dashboardMenu {
    transform: translate(-100px);
  }
  #spread {
    max-width: 250px;
    transform: translateX(0);
  }
}

@media screen and (max-width: 350px) {
  #spread {
    width: 80vw;
  }
}
