.dashboard-account {
  &-container {
    box-shadow: 0 0 20px #00000010;
    background-color: #fff;
    border-radius: 20px;
    display: grid;
    grid-template-rows: 35px 1fr 100px;
    row-gap: 15px;
    overflow: hidden;
  }
  &-head {
    padding: 0 20px;
    align-items: center;
    align-content: center;
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-self: last baseline;
    :last-child {
      justify-self: end;
    }
  }
  &-edit,
  &-logout {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: start;
    align-items: center;
    column-gap: 10px;
    font-weight: 600;
    overflow: hidden;
    width: 30px;
    white-space: nowrap;
    transition: 0.3s ease all;
    cursor: pointer;
    color: #015b4b;
  }

  &-logout {
    justify-content: end;
  }

  &-edit:hover {
    width: 180px;
  }
  &-logout:hover {
    width: 90px;
  }
  &-body {
    display: grid;
    grid-template-rows: auto auto auto;
    justify-content: center;
    text-align: center;
    align-content: center;
    row-gap: 5px;
  }
  &-photo {
    background-color: #00000018;
    border: 5px solid #015b4b;
    // width: 100px;
    border-radius: 100%;
    justify-self: center;
    overflow: hidden;
    // padding: 50px;
    width: clamp(50px, 15vw, 70px);
    height: clamp(50px, 15vw, 70px);

    img {
      width: 100%;
      height: 100%;
    }
  }
  &-name {
    margin-top: 15px;
    font-size: 18px;
    font-weight: 600;
    color: #015b4b;
  }
  &-mail {
    color: #434343dc;
    font-size: 14px;
    font-weight: 500;
  }
  &-last {
    padding: 0 8%;
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 45px;
    justify-content: center;
    align-content: center;
    text-align: center;
    column-gap: 10%;

    div {
      text-align: center;
      border-radius: 5px;
      font-size: 18px;
      font-weight: 500;
      display: grid;
      justify-items: center;
      align-items: center;
      a {
        cursor: pointer;
        color: #fff;
        justify-self: center;
        width: 100%;
      }
    }
  }

  &-deposit {
    background-color: #015b4b;
  }
  &-deposit:hover {
    background-color: #017d66;
  }
  &-withdraw {
    background-color: #a30000e7;
  }
  &-withdraw:hover {
    background-color: #a30000;
  }
}

@media screen and (min-width: 1440px) {
  .dashboard-account {
    &-photo {
      width: 6vw;
      height: 6vw;
    }
  }
}

@media screen and (min-width: 1800px) {
  .dashboard-account {
    min-height: 600px;
    &-name {
      font-size: 28px;
    }
    &-mail {
      font-style: 18px;
    }
    &-last {
      grid-template-rows: 60px;
      font-size: 24px;
    }
  }
}
