.recent-transactions {
  &-container {
    padding: 20px;
    background-color: #fff;
    height: calc(100% - 40px);
    border-radius: 20px;
    display: grid;
    grid-template-rows: 20px 1fr;
    row-gap: 15px;
  }
  &-head {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-content: center;
    align-self: last baseline;
    :first-child {
      font-size: 17px;
      font-weight: 600;
    }
    :last-child {
      justify-self: end;
      align-self: center;
      font-size: 15px;
      font-weight: 500;
      cursor: pointer;
      color: #015b4b;
    }
  }
  &-table {
    border: 1px solid #54545431;
    border-radius: 10px;
    display: grid;
    padding-top: 10px;
    height: auto;
    overflow-x: auto;
  }
}

.recent-transactions-table::-webkit-scrollbar {
  background-color: transparent;
  border-radius: 10px;
  height: 5px;
}
