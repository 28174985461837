.deposit {
  overflow: hidden;
  max-width: 100vw !important;
  padding-bottom: 30px;
  height: auto;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  position: relative;
  background-color: #d1efe9;

  &-container {
    animation: slidein 0.4s linear;
    padding: 100px 10px 50px 30px;
    align-content: center;
    display: grid;
    align-content: start;
    grid-template-rows: 50px auto auto;
    row-gap: 0px;
  }

  &-head {
    font-size: 24px;
    font-weight: 600;
    align-self: center;
    text-align: center;
    color: #136858;
  }

  &-types {
    height: 95px;
    margin-top: 10px;
    // background-color: #fff;
    width: 100%;
    max-width: 500px;
    justify-self: center;
    border-radius: 10px;
    // border: 1px solid #13685890;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: 1fr;
    margin-top: 20px;

    &-head {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }
    &-container {
      display: grid;
      justify-content: center;
      grid-template-columns: repeat(3, auto);
      align-content: center;
      column-gap: 10%;
      font-size: 20px;
    }

    &-icon {
      display: grid;
      align-content: center;

      span {
        transition: 0.4s ease;
        color: #136858;
        text-align: center;
        padding: 13px;
        width: 20px;
        background: #fff;
        border-radius: 100%;
        cursor: pointer;
      }
    }
  }

  &-body {
    margin-top: 15px;
    justify-self: center;
    height: auto;
    max-width: 500px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #13685890;
    border-radius: 10px;
    display: grid;
    align-content: start;
    grid-template-rows: auto 1fr auto;
    // padding: 10px;
  }
  &-amount {
    padding: 20px;
    display: grid;
    justify-items: start;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    row-gap: 10px;
    font-weight: 500;
    color: #0e5548;

    input {
      width: calc(100% - 10px);
      height: 30px;
      padding: 5px;
    }

    > div {
      width: 100%;
      display: grid;
      grid-auto-flow: column;
      justify-content: space-between;

      > :last-child {
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }

  &-submit {
    margin-top: 10px;
    height: 160px;
    padding: 15px 20px;
    display: grid;
    align-content: space-evenly;
    row-gap: 5px;

    > :first-child {
      font-weight: 500;
      // color: #0e5548;
    }

    input {
      width: calc(100% - 20px);
      padding: 5px;
      height: 30px;
    }

    &-screenshot {
      height: 50px;
      display: grid;
      align-content: center;
      row-gap: 20px;

      :first-child {
        font-weight: 600;
      }

      :last-child {
        cursor: pointer;
        display: grid;
        align-self: center;
        padding-left: 10px;
        border: 2px solid #00000060;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: auto;
        padding-top: 8px;
        padding-bottom: 8px;
        overflow: hidden;
        border-radius: 4px;
      }
      //   padding: 20px 0;
    }
    &-btn {
      border-radius: 5px;
      cursor: pointer;
      height: 40px;
      width: 180px;
      text-align: center;
      display: grid;
      align-items: center;
      color: #fff;
      font-size: 16px;
      background-color: #136858;
    }
  }
  &-details {
    overflow-y: hidden;
    height: auto;
    overflow-x: hidden;
    padding: 10px 0px;
    display: grid;
    grid-template-columns: repeat(3, 100%);
    grid-template-rows: auto;
    justify-items: center;
    column-gap: 20px;
    transition: 2s ease;
    // border: 10px solid #fff;
    // div {
    //   padding: 10px;
    // }
  }
  &-bank,
  &-paypal,
  &-bitcoin {
    width: calc(100% - 50px);
    display: grid;
    row-gap: 15px;
    align-content: start;
    height: 100px;
    transition: 0.3s ease-in;
    opacity: 0;
  }
  &-param {
    height: auto;
    display: grid;
    grid-template-rows: 20px 50px;
    row-gap: 5px;
    color: #0e5548;
  }
  &-copy {
    background-color: #d1efe9cd;
    border-radius: 5px;
    padding: 0 15px;
    display: grid;
    align-items: center;
    align-content: center;
    grid-template-columns: repeat(2, auto);
    color: #136858;

    :first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 95%;
    }

    :nth-child(2) {
      justify-self: end;
      padding: 0 20px 0;
      line-height: 25px;
      cursor: pointer;
      border-left: 2px solid #0e5548;
    }
  }
  &-qr {
    justify-self: center;
    margin-top: 15px;
    margin-bottom: -10px;
    border: 2px solid #13685820;
    border-radius: 10px;
    width: clamp(0px, 80vw, 250px);
   height: clamp(0px, 80vw, 250px);
   display: grid;

    >img{
      align-self: center;
      justify-self: center;
      width: 92%;
    }
  }
}

#show {
  height: auto !important;
  animation: shows 0.5s linear;
  z-index: 80;
  opacity: 1 !important;
}

.error_message {
  display: grid;
  background-color: #fff;
  width: 88%;
  max-width: 460px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #13685890;
  grid-template-columns: repeat(2, auto);
  justify-self: center;
  align-items: center;
  padding: 0 20px;
  margin-top: 25px;

  :last-child {
    justify-self: end;
  }
  span {
    cursor: pointer;
  }
}

@keyframes shows {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#activePayment span {
  background-color: #136858;
  color: #fff;
  transform: scale(1.2);
}

@keyframes slidein {
  from {
    opacity: 0;
    transform: translateX(-200px);
  }
  to {
    opacity: 1;
    margin-left: 0;
    transform: translateX(0);
    display: grid;
  }
}

@media screen and (max-width: 600px) {
  .deposit {
    &-container {
      padding: 80px 20px 10px;
    }
    &-types {
      &-icon {
        // width: 100%;
        // background-color: #fff;
      }
    }
  }
}
