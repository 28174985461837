.body {
  padding: 40px 0;
  display: grid;
  max-width: 100vw;
  height: 100%;
  grid-template-columns: 450px;
  justify-content: center;
  justify-items: center;
  grid-template-rows: auto;
}

.auth_container {
  padding: 20px;
  height: auto;
  display: grid;
  grid-template-rows: 50px auto;
  grid-template-columns: 1fr;
  row-gap: 90px;
}

.head {
  justify-self: center;
  text-align: center;
  font-weight: 600;
  font-size: 25px;
}

.formContainer {
  background-color: #fff;
  padding: 40px 40px;
  display: grid;
  row-gap: 40px;
  border-radius: 8px;
  justify-content: center;
  justify-items: center;
  box-shadow: 0 0 5px #b8b8b8af;
}

.formHead {
  font-size: 21px;
  color: #015b4b;
  justify-self: center;
  text-align: center;
  font-weight: 600;
  font-family: "M PLUS Rounded 1c", sans-serif;
}

.form {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 15px;
  justify-content: center;
}

#formItem {
  display: grid;
  grid-template-rows: 20px 50px;
  grid-template-columns: 380px;
  row-gap: 8px;
  font-size: 15px;
  font-weight: 600;
  color: #015b4b;
}

.input {
  display: grid;
}

input, select {
  border-radius: 4px;
  border: 2px solid #6e6e6ebe;
  transition: 0.1s ease-in all;
  outline: none;
  position: relative;
  font-weight: 500;
  font-size: 15px;
  padding: 10px 10px 10px 40px;
}

input:focus, select:focus {
  border-color: #015b4b;
}

#pwd {
  padding: 10px 40px;
}

.input .material-symbols-outlined {
  position: absolute;
  z-index: 1;
  /* align-self: center; */
  padding: 15px 10px;
  font-size: 22px;
}

.toggle {
  position: absolute;
  justify-self: end;
  padding-right: 40px;
  transition: 0.35s ease;
  opacity: 1;
}

#showToggle {
  opacity: 1;
  visibility: visible !important;
}

.material-symbols-outlined {
  user-select: none;
}

.options {
  display: grid;
  font-size: 13px;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: 1fr;
  align-content: center;
  align-items: center;
  color: #015b4b;
  align-self: start;
}

.remember {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  align-content: center;
  font-weight: 600;
  justify-self: start;
  column-gap: 5px;
  font-size: 14px;
  cursor: pointer;
}

#check {
  display: none;
  opacity: 0;
}

.err {
  color: red;
}

.button {
  display: grid;
  background-color: #015b4b;
  height: 50px;
  border-radius: 4px;
  grid-template-columns: repeat(2, auto);
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  /* position: relative; */
  cursor: pointer;
  font-weight: 500;
  column-gap: 10px;
  a {
    text-decoration: none;
    justify-self: end;
  }
}

#fade {
  opacity: 0.5;
}

.loader {
  display: none;
}

.loader {
  /* position: absolute; */
  width: 20px;
  height: 20px;
  /* background-color: #fff; */
  align-self: center;
  justify-self: start;
  //   margin-left: 15px;
  border-radius: 100%;
  border: 2.5px solid #fff;
  border-top-color: transparent;
  animation: loader 0.8s linear infinite;
  transform: rotate(0deg);
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.button:hover {
  background-color: #00a884;
}

.signup {
  margin-top: 20px;
}
.recover {
  margin-top: 5px;
}

.button div {
  cursor: pointer;
  color: #fff;
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-size: 18px;
  font-weight: 600;
}

.remember .material-symbols-outlined {
  border-radius: 100%;
  padding: 5px;
  position: relative;
  transition: 0.15s ease-in background;
  overflow: hidden;
}

.remember .material-symbols-outlined:active {
  background-color: #e0fcf7;
}

.remember .material-symbols-outlined::after {
  content: "check_box_outline_blank";
}

.remember .material-symbols-outlined::before {
  content: "";
  position: absolute;
  margin-left: -50px;
  margin-top: -50px;
  border-radius: 100%;
  background-color: #00000084;
  /* z-index: 10; */
  padding: 200px;
  opacity: 0;
  transition: 1s ease;
}

.material-symbols-outlined:active:before {
  /* margin: -100px; */
  transition: 0s !important;
  padding: 0px;
  padding-top: 50px;
  opacity: 1;
}

#check:checked + .material-symbols-outlined::after {
  content: "check_box";
}

#check:checked + .material-symbols-outlined {
  background-color: #e0fcf7;
}

/* 
#check:checked+.remember .material-symbols-outlined::after {
    display: none;
} */

.forgot {
  color: #00a884;
  justify-self: end;
  text-align: right;
  font-weight: 600;
  cursor: pointer;
}

.remember .material-symbols-outlined {
  font-size: 20px;
}

.alt,
.copyright {
  text-align: center;
  font-size: 12px;
  color: #384a46;
}

.copyright {
  font-size: 14px;
  font-weight: 500;
}

.alt span {
  color: #015b4b;
  font-size: 14px;
  margin-left: 5px;
  cursor: pointer;
  font-weight: 600;
}

.head {
  &-auth {
    justify-self: center;
    img {
      width: 120px;
    }
  }
  &-dashboard {
    align-self: center;
    margin-top: 4px;
    img {
      width: clamp(160px, 12vw, 180px);
    }
  }
}

@media screen and (max-width: 500px) {
  body {
    background: #fff;
  }

  .body {
    min-width: 0;
    grid-template-columns: 100vw !important;
  }

  .auth_container {
    padding: 0 !important;
    grid-template-columns: 100vw;
    justify-items: center;
    /* grid-template-columns: calc(400px - 60px); */
  }

  .formContainer {
    padding: 0;
    box-shadow: none;
  }

  #formItem {
    width: 82vw;
    grid-template-columns: 1fr;
  }
}
